/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";

// Lib
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

// Ui
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";

// I18n
import { useTranslation } from "react-i18next";

const ImageCropper: React.FC<{
  onCrop: (crop: File) => void,
  urlCrop: string | undefined;
  setUrlCrop: React.Dispatch<React.SetStateAction<string | undefined>>
}> = ({onCrop, urlCrop, setUrlCrop}) => {
  const {t} = useTranslation();
  const [image, setImage] = useState<unknown>();
  const [cropper, setCropper] = useState<any>();

  const [open, setOpen] = useState<boolean>(false);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
      setOpen(true);
    };

    if (files && files.length > 0) {
      reader.readAsDataURL(files[0]);
    }
  };

  const getCropData = async () => {
    if (typeof cropper !== "undefined") {
      const image = await cropper.getCroppedCanvas().toDataURL();
      const response = await fetch(image);
      const blob = await response.blob();
      const file = new File([blob], "cropImage", { type: "image/jpg" });

      if (image) {
        setUrlCrop(image);
        onCrop(file);
      }
      setOpen(false);
    }
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}>
          <input
            type="file"
            onChange={e => onChange(e)}
            accept=".jpg,.png,.jpeg,.svg"
          />

          {urlCrop && 
          <img style={{width: "100px", height: "100px"}} src={urlCrop} />}
        </Box>
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
        >
          <DialogTitle>
            {t("resizeImage")}
          </DialogTitle>
          <DialogContent>
            <>
              {image && <Cropper
                style={{ height: 400, width: "100%" }}
                aspectRatio={1}
                zoomTo={0}
                initialAspectRatio={1}
                src={image as never}
                viewMode={1}
                minCropBoxHeight={100}
                minCropBoxWidth={100}
                background={false}
                responsive={true}
                autoCropArea={1}
                checkOrientation={false}
                onInitialized={(instance) => {
                  setCropper(instance);
                }}
                guides={true}
              />}
            </>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" autoFocus onClick={() => setOpen(false)}>
              {t("cancel")}
            </Button>
            <Button
              variant="contained"
              onClick={() => getCropData()}>
              {t("btnResize")}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};

export default ImageCropper;

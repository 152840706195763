import {
  collection, 
  query,
  where,
  limit,
  getDocs,
  Timestamp
} from "firebase/firestore";
import { collections, IFuneralCompany } from "type-absenso";
import { IFuneralFront } from "./types/frontDocument";
import { db } from "../config";

export const searchFuneralCompany = async ({
  errorAction,
  search
}: {
    errorAction: () => void,
    search: string;
}) => {
  try {
    const dataFuneral: Array<IFuneralFront> = [];
    const funeralCompanyRef = collection(db, collections.funeralCompany);
    const q = query(
      funeralCompanyRef,
      where("companyName", ">=", search.toUpperCase()),
      where("companyName", "<=", search.toLowerCase()+"\uf8ff"),
      limit(10)
    );
    const snaps = await getDocs(q);

    for await (const snap of snaps.docs) {
      const data = snap.data() as IFuneralCompany<Timestamp>;
      dataFuneral.push({...data, id: snap.id});
    }

    return dataFuneral;
  } catch (error) {
    errorAction();
  }
};
import { IDataFrontMail } from "type-absenso";
import { postAuth } from "./config";
import { sendMailUrl } from "./url";

export const sendMailRequest = async ({
  data,
}: {
    data: IDataFrontMail,
}) => {
  await postAuth(sendMailUrl, data);
};
import React from "react";

// Ui
import { Card, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

// Router
import { Link } from "react-router-dom";
import { routing } from "../../router/config";

const Item = ({text, path}: {text: string, path: string}) => {
  const {t} = useTranslation();
  return(
    <Grid item xs={12} md={6}>
      <Link to={path} style={{textDecoration: "none"}}>
        <Card sx={{
          height: theme => theme.spacing(15),
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}>
          <Typography variant="h6" sx={{
            textTransform: "uppercase"
          }}>{t(text)}</Typography>
        </Card>
      </Link>
    </Grid>
  );
};

const arrayBtn: Array<{text: string, path: string}> = [
  {text: "createUserAdmin", path: routing.createUser.adminUser},
  {text: "addFuneralAccount", path: routing.createUser.funeralUser},
];

const Home = () => {
  return(
    <Grid container spacing={2}>
      {arrayBtn.map((elt, i) => (
        <React.Fragment key={i}>
          <Item text={elt.text} path={elt.path} />
        </React.Fragment>
      ))}
    </Grid>
  );
};

export default Home;

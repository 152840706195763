import React from "react";

// Router
import { routing } from "../config";
import { Navigate, Route, Routes } from "react-router-dom";

// Layout
import LayoutUnConnected from "../../views/layout/layoutunconnected";

// Views
import Signin from "../../views/auth/signin";

const UnauthenticatedRoute = () => {
  return(
    <LayoutUnConnected>
      <Routes>
        <Route path="/" element={
          <Navigate to={routing.auth.signin} />
        }/>
        <Route path={routing.auth.signin} element={<Signin />} />
      </Routes>
    </LayoutUnConnected>
  );
};

export default UnauthenticatedRoute;

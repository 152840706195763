import { GridColDef } from "@mui/x-data-grid";

export const arrayProduct = [
  "id",
  "name",
  "price",
];

export const columnProduct: Array<GridColDef> = arrayProduct.map(
  element => {
    return {field: element, headerName: element, width: 250};
  }
);
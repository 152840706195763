import React, {createContext, useState, useContext} from "react";

// Ui
import {Snackbar, Alert} from "@mui/material";

// Types
import {IAlert} from "./types/alert";

export const AlertContext = createContext<
    null | 
    {createAlert:(data: IAlert) => void}>(null);

export const useAlert = () => {
  const setAlert = useContext(AlertContext);

  return setAlert?.createAlert;
};

const AlertProvider: React.FC = ({children}) => {
  const [open, setOpen] = useState<boolean>(true);
  const [alert, setAlert] = useState<IAlert>();

  const createAlert: (data: IAlert) => void = (data) => {
    setOpen(true);
    setAlert(data);
  };

  return (
    <>
      <AlertContext.Provider value={{createAlert: createAlert}}>
        {children}
        {alert ? <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={() => setOpen(false)}
          ContentProps={{
            sx: {
              backgroundColor: "red!important"
            }
          }}
        >
          <Alert  
            onClose={() => setOpen(false)}
            severity={alert.type} sx={{width: "100%"}}>
            {alert.message}
          </Alert>
        </Snackbar> : null}
      </AlertContext.Provider>
    </>
  );
};

export default AlertProvider;

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useState, useEffect} from "react";

// Ui
import {
  Box,
  Tabs,
  Typography,
  Tab,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from "@mui/material";
import { DataGrid, GridSelectionModel } from "@mui/x-data-grid";
import { columnKit, columnStock } from "../../utils/assetDataGrid/stock";
import { LoadingButton } from "@mui/lab";

// I18n
import { useTranslation } from "react-i18next";

// Backend
import {
  getKitWithoutPlace,
  getSTockWithoutPlace
} from "../../backend/queries/stock";
import { searchFuneralCompany } from "../../backend/queries/funeralCompany";
import { postAuth } from "../../backend/queries/config";
import { transferKitUrl, transferUrl } from "../../backend/queries/url";

// type
import {
  IKitFront,
  IStockFront
} from "../../backend/queries/types/frontDocument";
import { IAssetAutoCompleteField } from "../../components/forms/types";
import { formatformbackend } from "../../utils/formatformbackend";
import { collections, IDataFrontTransfer } from "type-absenso";

// Form
import { useForm } from "react-hook-form";
import FormComponent from "../../components/forms/formcomponent";
import { useAlert } from "../../provider/error/hooks/useAlert";


const values = [
  "stock",
  "kit"
];

const TransferStock = () => {
  const {t} = useTranslation();
  const {watch, control, formState, handleSubmit} = useForm();
  const alert = useAlert();
  const [value, setValue] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  const [idsStock, setIdsStock] = useState<GridSelectionModel>();
  const [idsKit, setIdsKit] = useState<GridSelectionModel>();
  const [stockRows, setStockRows] = useState<
    Array<IStockFront>
  >();
  const [kitRows, setKitRows] = useState<
    Array<IKitFront>
  >();

  const [optionsFuneral, setOptionsFuneral] = useState<
    Array<IAssetAutoCompleteField<string>>
  >();

  useEffect(() => {
    (async() => {
      setLoading(true);
      switch (value) {
      case 0:
        const dataStocks = await getSTockWithoutPlace({
          errorAction: () => alert?.createAlert({
            type: "error",
            message: t("errorStandard")
          })
        });
        setStockRows(dataStocks);
        break;
      case 1:
        const dataKits = await getKitWithoutPlace({
          errorAction: () => alert?.createAlert({
            type: "error",
            message: t("errorStandard")
          })
        });
        setKitRows(dataKits);
        break;
      default:
        break;
      }
      setLoading(false);
    })();
  }, [value]);

  const goodData = () => {
    switch (value) {
    case 0:
      return {
        column: columnStock,
        rows: stockRows,
        setIds: setIdsStock,
        ids: idsStock,
        collection: collections.stock,
        url: transferUrl,
      };
    case 1:
      return {
        column: columnKit,
        rows: kitRows,
        setIds: setIdsKit,
        ids: idsKit,
        collection: collections.kit,
        url: transferKitUrl,
      };
    default:
      break;
    }
  };

  const searchFuneral = async (data: string) => {
    const dataFuneral = await searchFuneralCompany({
      search: data,
      errorAction: () => false
    });
    if (dataFuneral) {
      setOptionsFuneral(
        dataFuneral?.map(el => {
          return {
            value: el.id,
            label: el.companyName,
          };
        })
      );
    }
    
  };

  const submit = async (data: any) => {
    if (goodData()?.ids && goodData()?.collection) {
      setLoading(true);
      const dataSend: IDataFrontTransfer = {
        ids: goodData()?.ids as Array<string>,
        idFuneral: formatformbackend(data) as {funeral: string},
        collection: goodData()?.collection as string,
      };
      try {
        await postAuth(goodData()?.url as string, dataSend);
        setOpen(false);
        alert?.createAlert({
          type: "success",
          message: t("")
        });
      } catch (error) {
        alert?.createAlert({
          type: "error",
          message: t("errorStandard")
        });
      }
      
      setLoading(false);
    }
  };

  return(
    <>
      
      <Tabs
        sx={{width: "100%"}}
        value={value} onChange={(e, value) => setValue(value)} >
        {values.map((elt, i) => (
          <Tab sx={{minWidth: "50%"}} key={i} label={t(elt)} />
        ))}
      </Tabs>
      
      <Typography component="h4" variant="h4" sx={{pt: 2}}>
        {`${t("transferStock")} ${t(values[value])}`}
      </Typography>

      <Box pt={2}>
        <DataGrid 
          onSelectionModelChange={(ids) => {
            goodData()?.setIds(ids);
          }}
          checkboxSelection={true}
          loading={loading}
          autoHeight
          rows={goodData()?.rows || []}
          columns={goodData()?.column || []}
        />

        <Button 
          variant="contained" 
          sx={{mt: 2, width: "100%"}}
          onClick={() => {
            const idsData = goodData()?.ids;
            if (idsData && idsData.length > 0) {
              setOpen(true);
            }
          }}
        >
          {t("transferStock")}
        </Button>
      </Box>

      <Dialog 
        open={open}
        fullWidth={true}
        onClose={() => setOpen(false)}
      >
        <DialogTitle>
          {t("transferStock")}
        </DialogTitle>
        <DialogContent>
          <FormComponent 
            watch={watch}
            control={control}
            formState={formState}
            arrayForms={[
              {
                type: "Autocomplete",
                elementAutoComplete: {
                  id: "funeral",
                  name: "funeral",
                  label: "funeral",
                  options: optionsFuneral || [],
                  onChangeTextField: (data) => {
                    searchFuneral(data);
                  }
                }
              }
            ]}
          />
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={loading}
            variant="contained"
            onClick={handleSubmit(submit)}
          >
            {t("transfer")}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TransferStock;

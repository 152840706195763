import { IForm } from "../../types";

export const signinForm: Array<IForm> = [
  {
    type: "TextField",
    elementTextField: {
      id: "email",
      type: "email",
      name: "email",
      label: "email"
    }
  },
  {
    type: "TextField",
    elementTextField: {
      id: "password",
      type: "password",
      name: "password",
      label: "password"
    }
  },

];
import React, { useState, useEffect } from "react";

// Ui
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { columnProduct } from "../../utils/assetDataGrid/product";
import { LoadingButton } from "@mui/lab";

// I18n
import { useTranslation } from "react-i18next";

// Backend
import { getAllProduct } from "../../backend/queries/product";
import { postAuth } from "../../backend/queries/config";

// Type
import { collections, IProduct } from "type-absenso";

// Form
import FormComponent from "../../components/forms/formcomponent";
import { useForm } from "react-hook-form";
import { addProductForm } from "../../components/forms/asset/stock/product";
import { addProductUrl } from "../../backend/queries/url";

// Hooks
import { useAlert } from "../../provider/error/hooks/useAlert";
import ImageCropper from "../../components/utils/imageCropper";
import { uploadFile } from "../../utils/uploadFile";

const AddProduct = () => {
  const {t} = useTranslation();
  const {watch, formState, control, handleSubmit} = useForm();
  const alert = useAlert();

  const [loading, setLoading] = useState<boolean>(false);
  const [reload, setReload] = useState<number>(0);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [rows, setRows] = useState<Array<IProduct>>();

  const [open, setOpen] = useState<boolean>(false);

  const [crop, setCrop] = useState<File>();
  const [urlCrop, setUrlCrop] = useState<string>();

  useEffect(() => {
    (async() => {
      setLoading(true);
      const allProduct = await getAllProduct({
        errorAction: () => alert?.createAlert({
          type: "error",
          message: t("errorStandard")
        }),
      });

      if (allProduct) {
        setRows(allProduct);
      }
      setLoading(false);
    })();
  }, [reload]);

  const onSubmit = async (data: {name: string, price: string}) => {
    setLoadingSubmit(true);
    if (data && data?.price && crop) {
      try {
        const image = await uploadFile(
          {file: crop, collection: collections.product}
        );
        const dataProduct: IProduct = {
          ...data,
          price: parseInt(data?.price),
          image,
        };
        await postAuth(addProductUrl, dataProduct);
        setOpen(false);
        setReload(reload + 1);
      } catch (error) {
        alert?.createAlert({
          type: "error",
          message: t("errorStandard")
        });
      }
    }
    setLoadingSubmit(false);
  };

  return(
    <>
      <Box sx={{
        display: "flex",
        justifyContent: "space-between"
      }}>
        <Typography component="h4" variant="h4">
          {t("addProduct")}
        </Typography>

        <Button variant="contained" onClick={() => setOpen(true)}>
          {t("addProduct")}
        </Button>
      </Box>

      <Box pt={2}>
        <DataGrid 
          loading={loading}
          autoHeight
          rows={rows || []}
          columns={columnProduct}
        />
      </Box>

      <Dialog
        open={open}
        fullWidth={true}
        onClose={() => setOpen(false)}
      >
        <DialogTitle>
          {t("addProduct")}
        </DialogTitle>
        <DialogContent>
          <Box my={1}>
            <ImageCropper
              onCrop={(crop) => setCrop(crop)}
              urlCrop={urlCrop}
              setUrlCrop={setUrlCrop}
            />
          </Box>
          <FormComponent 
            watch={watch}
            formState={formState}
            control={control}
            arrayForms={addProductForm}
          />
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={loadingSubmit}
            variant="contained"
            onClick={handleSubmit(onSubmit as never)}
          >
            {t("add")}
          </LoadingButton>
        </DialogActions>
      </Dialog>

    </>
  );
};

export default AddProduct;

import { createTheme } from "@mui/material";

// Color
import { darkTurquoise } from "./color";

export const theme = createTheme({
  palette: {
    mode: "light",
  }
});

export const customTheme = createTheme(theme, {
  components: {
    MuiButton: {
      variants: [
        {
          props: {variant: "contained"},
          style: {
            backgroundColor: darkTurquoise,
            height: theme.spacing(6),
            "&:hover": {
              background: "#002e3b",
            },
          }
        },
        {
          props: {variant: "outlined"},
          style: {
            borderColor: darkTurquoise,
            color: darkTurquoise,
            height: theme.spacing(6),
            "&:hover": {
              background: "#002e3b",
              color: "#fff"
            },
          }
        },
        {
          props: {variant: "text"},
          style: {
            color: darkTurquoise,
            "&:hover": {
              background: "none",
            },
          }
        }
      ]
    }
  }
});
import React, { useEffect, useState } from "react";

// Ui
import { Typography, Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";

// I18n
import { useTranslation } from "react-i18next";

// Component
import ImageCropper from "../../components/utils/imageCropper";

// Form
import FormComponent from "../../components/forms/formcomponent";
import { useForm } from "react-hook-form";
import { createdeceasedForm, IDeceasedForm }
  from "../../components/forms/asset/deceasded/create";


// Backend
import { addDeathProfile } from "../../backend/queries/death";
import { formatAdress, getAddressDetails } from "../../backend/queries/address";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL
} from "firebase/storage";
import { getAuth } from "firebase/auth";
import { doc, DocumentReference, Timestamp } from "firebase/firestore";
import { IAssetAutoCompleteField } from "../../components/forms/types";
import { geohashForLocation } from "geofire-common";
import { app, db } from "../../backend/config";

// Hooks
import { useAlert } from "../../provider/error/hooks/useAlert";

// Types
import { collections, IDeath, IGeolocation } from "type-absenso";

// Lib
import { uid } from "uid";

const CreateDeaceased = () => {
  const {t} = useTranslation();
  const alert = useAlert();
  const auth = getAuth(app);
  const {watch, formState, control, handleSubmit, reset} = useForm();

  const [optionsAdress, setOptionsAddress] =
    useState<Array<IAssetAutoCompleteField<string>>>();
  const [formattedAddress, setFormattedAddress] = useState<string>();
  const [geocode, setGeocode] = useState<IGeolocation>();
  const [crop, setCrop] = useState<File>();
  const [urlCrop, setUrlCrop] = useState<string>();

  const [loading, setLoading] = useState<boolean>();

  const onChangeAddress = async (data: string) => {
    const addresses = await formatAdress({
      errorAction: () => alert?.createAlert({
        type: "error",
        message: t("errorStandard")
      }),
      data,
    });

    if (addresses) {
      setOptionsAddress(addresses.map(elt => {
        return {label: elt.description, value: elt.place_id};
      }));
    }

  };

  useEffect(() => {
    if (watch("lastAddress")) {
      (async() => {
        const data = watch("lastAddress") as IAssetAutoCompleteField<string>;
        const details = await getAddressDetails({
          errorAction: () => alert?.createAlert({
            type: "error",
            message: t("errorStandard")
          }),
          placeId: data.value
        });

        if (details?.result.geometry.location) {
          const hash = geohashForLocation([
            details?.result.geometry.location.lat,
            details?.result.geometry.location.lng
          ]);
          setFormattedAddress(details?.result.formatted_address);
          setGeocode({
            ...details?.result.geometry.location,
            hash,
          });
        }

      })();
    }
  }, [watch("lastAddress")]);

  const onSubmit = async (data: unknown) => {
    if (crop && formattedAddress && geocode) {
      setLoading(true);
      try {
        const dataForm = data as IDeceasedForm;
        const storage = getStorage(app);
        const deathImgRef = ref(
          storage, 
          `${collections.death}/${uid(32)}`
        );
        
        await uploadBytesResumable(
          deathImgRef,
          crop,
        );
        const url = await getDownloadURL(deathImgRef);
        const dataToSend: IDeath<DocumentReference, Timestamp> = {
          admin: ["absenso"],
          profilePicture: url,
          name: dataForm.name,
          firstName: dataForm.firstName,
          fullname: `${dataForm.firstName} ${dataForm.name}`,
          lastAddress: formattedAddress,
          geoLocation: geocode,
          nOfCandlesOrdered: 0,
          dateOfBirth: Timestamp.fromDate(dataForm.dateOfBirth),
          dateOfDeath: Timestamp.fromDate(dataForm.dateOfDeath),
          createAt: Timestamp.fromDate(new Date()),
          createBy: doc(db, `${collections.users}/${auth.currentUser?.uid}`),
          numberOfBouquetsLaid: 0,
          numberOfCandlePurchases: 0,
          numberOfMemories: 0,
          numberOfTestimoniesLeft: 0,
          numberOfThoughtsSend: 0,
          coverPicture: "default",
          description: null,
          religion: dataForm.religion && dataForm.religion.value,
          type: "paid",
        };
        await addDeathProfile({
          errorAction: () => false,
          data: dataToSend,
        });
        reset();
        setFormattedAddress(undefined);
        setGeocode(undefined);
        setCrop(undefined);
        setUrlCrop(undefined);
        alert?.createAlert({
          type: "success",
          message: t("successDesceaded")
        });
      } catch (error) {
        alert?.createAlert({
          type: "error",
          message: t("errorStandard")
        });
      }
      setLoading(false);
    }
  };

  return(
    <>
      <Typography variant="h4" component="h4">
        {t("createDeceasedPage")}
      </Typography>

      <Box pt={2}>
        <ImageCropper
          urlCrop={urlCrop}
          setUrlCrop={setUrlCrop}
          onCrop={(crop) => setCrop(crop)}
        />
        
        <Box py={1}>
          <FormComponent 
            watch={watch}
            formState={formState}
            control={control}
            arrayForms={createdeceasedForm({
              onChangeAddress,
              optionsAddress: optionsAdress || [],
            })}
          />
          <Box onClick={handleSubmit(onSubmit)} pt={2} sx={{width: "100%"}}>
            <LoadingButton
              loading={loading}
              variant="contained"
              sx={{width: "100%"}}
            >
              {t("create")}
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CreateDeaceased;
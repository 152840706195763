import { postAuth } from "./config";
import { addressFormatUrl, detailsAddressUrl } from "./url";

export const formatAdress = async ({
  errorAction,
  data
}: {
    errorAction: () => void;
    data: string;
}) => {
  try {
    const adresses = await postAuth(addressFormatUrl, {data});

    return adresses?.data as Array<{
        description: string,
        place_id: string;
    }>;
  } catch (error) {
    errorAction();
  }
};

export const getAddressDetails = async ({
  errorAction,
  placeId,
}: {
    errorAction: () => void;
    placeId: string;
}) => {
  try {
    const details = await postAuth(detailsAddressUrl, {data: placeId});

    return details?.data as {
        result: {
            formatted_address: string;
            geometry: {
                location: {
                    lat: number;
                    lng: number;
                }
            }
        }
    };

  } catch (error) {
    errorAction();
  }
};
import React, {useState} from "react";

// Ui
import {
  AppBar,
  Toolbar,
  Typography,
  Drawer,
  Box,
  ListItem,
  ListItemText,
  ListItemButton,
  List,
  IconButton,
  Tooltip,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

// Routing
import { useNavigate } from "react-router-dom";
import { TArrayMenu } from "./type/types";

// i18n
import { useTranslation } from "react-i18next";
import { useAuth } from "../../provider/auth/hooks/useAuth";
import { useUser } from "../../provider/auth/hooks/useUser";
import { darkTurquoise } from "../../ui/color";

const drawerWidth = 240;

const LayoutAdmin: React.FC<{
    children: React.ReactElement;
    arrayMenu: TArrayMenu;
}> = ({children, arrayMenu}) => {
  const [open, setOpen] = useState<boolean>(false);
  const {t} = useTranslation();
  const navigate = useNavigate();

  const changePath = (path: string) => {
    navigate(path);
    setOpen(false);
  };

  const TooltipIcon = ({size}: {size: string}) => {
    const [openTooltip, setOpenTooltip] = useState<boolean>(false);
    const auth = useAuth();
    const user = useUser();

    return (
      <Tooltip
        onClick={() => setOpenTooltip(true)}
        open={openTooltip}
        onOpen={() => setOpenTooltip(true)}
        onClose={() => setOpenTooltip(false)}
        disableTouchListener={true}
        title={
          <List>
            <ListItem disablePadding>
              <ListItemButton onClick={() => auth && auth.signout()}>
                <ListItemText primary={t("auth.signout")} />
              </ListItemButton>
            </ListItem>
          </List>
        }
      >
        <Box sx={{
          height: size,
          width: size,
          borderRadius: "50%",
          backgroundColor: "#FFFFFF",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}>
          <Typography sx={{color: "black", fontWeight: "500"}}>
            {user?.name.slice(0, 2).toUpperCase()}
          </Typography>
        </Box>
      </Tooltip>
    );
  };

  return (
    <>
      <AppBar
        position="fixed" sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: darkTurquoise
        }}>
        <Toolbar sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}>
          {/* On mobile */}
          <Box sx={{display: {xs: "block", md: "none"}}}>
            <TooltipIcon size="36px" />
          </Box>
          <Typography>{t("title")}</Typography>

          {/* On desktop */}
          <Box sx={{display: {xs: "none", md: "block"}}}>
            <TooltipIcon size="48px" />
          </Box>

          <IconButton
            sx={{
              color: "white",
              display: {md: "none", xs: "block"},
              transform: `rotate(${open ? "90deg" : "0deg"})`
            }}
            onClick={() => setOpen(open ? false : true)}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          display: {md: "block", xs: "none"},
          width: drawerWidth,
          flexShrink: 0,
          ["& .MuiDrawer-paper"]: {width: drawerWidth, boxSizing: "border-box"},
        }}
      >
        <Toolbar />
        <Box sx={{overflow: "auto"}}>
          <List>
            {arrayMenu.map((text, i) => (
              <ListItem
                button
                onClick={() => changePath(text.path)}
                key={i}
              >
                <ListItemText>{t(text.title)}</ListItemText>
              </ListItem>
            ))}
          </List>
        </Box>

      </Drawer>
            
      <Drawer
        open={open}
        variant="temporary"
        sx={{
          display: {md: "none", xs: "block"},
          width: drawerWidth,
          flexShrink: 0,
          ["& .MuiDrawer-paper"]: {width: drawerWidth, boxSizing: "border-box"},
        }}
      >
        <Toolbar />
        <Box sx={{overflow: "auto"}}>
          <List>
            {arrayMenu.map((text, i) => (
              <ListItem button onClick={() => changePath(text.path)} key={i}>
                <ListItemText>{t(text.title)}</ListItemText>
              </ListItem>
            ))}
          </List>
        </Box>

      </Drawer>
      <Box
        component="main"
        sx={{
          width: {md: `calc(100% - ${drawerWidth}px)`, xs: "100%"},
          minHeight: theme => `calc(100vh - ${theme.spacing(6)})`,
          overflow: "hidden",
          position: "relative",
          flexGrow: 1,
          p: 0,
          mt: theme => theme.spacing(8),
          float: "right",
          padding: 2,
        }}
      >
        {children}
      </Box>
    </>
  );
};

export default LayoutAdmin;
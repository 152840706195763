import React, { useEffect, useState } from "react";

// Ui
import { Typography, Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";

// I18n
import { useTranslation } from "react-i18next";

// Form
import AutoCompleteReactHookForm from "../../components/forms/autocomplete";
import { useForm } from "react-hook-form";

// Type
import { IAssetAutoCompleteField } from "../../components/forms/types";
import { ILinkAdminForm }
  from "../../components/forms/asset/deceasded/linkadmin";

// Backend
import {
  addAdminForADeath,
  searchProfileDeath
} from "../../backend/queries/death";
import { searchUser } from "../../backend/queries/users";
import { sendMailRequest } from "../../backend/queries/mail";

// Hooks
import { useAlert } from "../../provider/error/hooks/useAlert";

const LinkAdmin = () => {
  const {t} = useTranslation();
  const {control, formState, handleSubmit, reset} = useForm();
  const alert = useAlert();

  const [search, setSearch] = useState<string>();
  const [optionsDeath, setOptionsDeath] = useState<
    Array<IAssetAutoCompleteField<string>>
  >();

  const [searchAdmin, setSearchAdmin] = useState<string>();
  const [optionsAmin, setOptionsAmin] = useState<
    Array<IAssetAutoCompleteField<string>>
  >();

  const [loading, setLoading] = useState<boolean>();

  useEffect(() => {
    (async() => {
      if (search) {
        const deaths = await searchProfileDeath({
          search,
          errorAction: () => alert?.createAlert({
            type: "error",
            message: t("errorStandard")
          }),
        });

        if (deaths) {
          const arrayD: Array<
          IAssetAutoCompleteField<string>
        > = deaths.map(elt => {
          return {
            label: elt.fullname,
            value: elt.id,
            image: elt.profilePicture,
          };
        });

          setOptionsDeath(arrayD);
        }
      } 
    })();
  }, [search]);

  useEffect(() => {
    (async() => {
      if (searchAdmin) {
        const users = await searchUser({
          search: searchAdmin,
          errorAction: () => alert?.createAlert({
            type: "error",
            message: t("errorStandard")
          })
        });

        if (users) {
          const arrayU: Array<
            IAssetAutoCompleteField<string>
          > = users.map(elt => {
            return {
              label: elt.email,
              value: elt.id
            };
          });

          setOptionsAmin(arrayU);
        }
      }
    })();
  }, [searchAdmin]);

  const onSubmit = async (data: unknown) => {
    const dataForm = data as ILinkAdminForm;
    setLoading(true);
    try {
      await addAdminForADeath({
        idDeath: dataForm.death.value,
        idUser: dataForm.newAdmin.value,
      });
      await sendMailRequest({
        data: {
          subject: t("subjectEmailLinkAdmin"),
          text: t("emailLinkAdmin", {fullname: dataForm.death.label}),
          to: dataForm.newAdmin.label
        }
      });
      reset();
      setLoading(false);
      alert?.createAlert({
        type: "success",
        message: t("successAdminLink")
      });
    } catch (error) {
      alert?.createAlert({
        type: "error",
        message: t("errorStandard")
      });
    }
  };

  return(
    <>
      <Typography component="h4" variant="h4">
        {t("linkAnAdmin")}
      </Typography>
    
      <Box pt={2}>
        <AutoCompleteReactHookForm 
          control={control}
          error={Boolean(formState.errors["death"])}
          onChangeTextField={(search: string) => setSearch(search)}
          element={{
            id: "death",
            name: "death",
            label: "nameOfTheDeceased",
            addComponentInOption: (
              option: IAssetAutoCompleteField<unknown>
            ) => {
              if (option.image) {
                return(
                  <Box mr={2}>
                    <img src={option.image} loading="lazy" style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "25px"
                    }} />
                  </Box>
                );
              }
              return(<></>);
            },
            options: optionsDeath || [],
          }

          }
        />
        <Box pt={2}>
          <AutoCompleteReactHookForm
            control={control}
            error={Boolean(formState.errors["newAdmin"])}
            onChangeTextField={(search: string) => setSearchAdmin(search)}
            element={{
              id: "newAdmin",
              name: "newAdmin",
              label: "newAdmin",
              options: optionsAmin || [],
            }

            }
          />
        </Box>
        
        <Box pt={2} sx={{width: "100%"}}>
          <LoadingButton
            loading={loading}
            onClick={handleSubmit(onSubmit)}
            sx={{width: "100%"}}
            variant="contained"
          >
            {t("create")}
          </LoadingButton>
        </Box>
      </Box>
    </>
  );
};

export default LinkAdmin;

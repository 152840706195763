import { collection, getDocs, query } from "firebase/firestore";
import { collections, IProduct } from "type-absenso";
import { db } from "../config";

export const getAllProduct = async ({
  errorAction
}: {
    errorAction: () => void;
}) => {
  try {
    const dataProduct: Array<IProduct> = [];
    const productRef = collection(db, collections.product);
    const q = query(
      productRef,
    );
    const snaps = await getDocs(q);
    for await (const snap of snaps.docs) {
      const data = snap.data() as IProduct;
      dataProduct.push({
        ...data,
        id: snap.id
      });
    }

    return dataProduct;
  } catch (error) {
    errorAction();
  }
};
import React, { useState } from "react";

// Ui
import { Typography, Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";

// I18n
import { useTranslation } from "react-i18next";

// Form
import FormComponent from "../../components/forms/formcomponent";
import { useForm } from "react-hook-form";
import {
  createUserAdminForm,
  IAdminUserForm
} from "../../components/forms/asset/user/admin";
import { formatformbackend } from "../../utils/formatformbackend";

// Type
import { IDataFrontAdminUser } from "type-absenso";

// Hooks
import { useAlert } from "../../provider/error/hooks/useAlert";
import { createAdminUser } from "../../backend/queries/users";

const AdminUser = () => {
  const {t} = useTranslation();
  const alert = useAlert();
  const {watch, formState, control, handleSubmit} = useForm();

  const [loading, setLoading] = useState<boolean>();

  const onSubmit = async (data: unknown) => {
    setLoading(true);
    const dataForm = data as IAdminUserForm;
    const dataBack = formatformbackend(dataForm as never);
    await createAdminUser({
      data: dataBack as unknown as IDataFrontAdminUser,
      successAction: () => alert?.createAlert({
        type: "success",
        message: t("successUserAdmin")
      }),
      errorAction: () => alert?.createAlert({
        type: "error",
        message: t("errorStandard")
      })
    });
    setLoading(true);
  };

  return(
    <>
      <Typography variant="h4" component="h4">
        {t("createUserAdmin")}
      </Typography>

      <Box pt={2}>
        <FormComponent 
          watch={watch}
          formState={formState}
          control={control}
          arrayForms={createUserAdminForm}
        />

        <Box pt={2} sx={{width: "100%"}}>
          <LoadingButton
            loading={loading}
            onClick={handleSubmit(onSubmit)}
            sx={{width: "100%"}}
            variant="contained"
          >
            {t("create")}
          </LoadingButton>
        </Box>
      </Box>
    </>
  );
};

export default AdminUser;
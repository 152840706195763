import React from "react";

// Router
import { HashRouter } from "react-router-dom";

// Ui
import { customTheme } from "./ui/theme";
import { CssBaseline, ThemeProvider } from "@mui/material";

// Provider
import AlertProvider from "./provider/error/alertprovider";
import AuthProvider from "./provider/auth/authprovider";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateAdapter from "@mui/lab/AdapterDateFns";

const App = () => {
  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <ThemeProvider theme={customTheme}>
        <CssBaseline />
        <HashRouter>
          <AlertProvider>
            <AuthProvider />
          </AlertProvider>
        </HashRouter>
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export default App;

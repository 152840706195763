import axios from "axios";

import { getAuth } from "firebase/auth";
import {app} from "../config";

export const postAuth = async (url: string, data: unknown) => {
  try {
    const auth = getAuth(app);
    const token = await auth.currentUser?.getIdToken();
    
    return axios.post(
      url,
      JSON.stringify(data),
      {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
          mode: "cors",
          cache: "default",
        }
      });
  } catch (error) {
    console.error(error);
  }
};
import { IAssetAutoCompleteField, IForm } from "../../types";
import { religionTypes } from "type-absenso";

interface ICreateDeceasedForm {
  onChangeAddress: (data: string) => void;
  optionsAddress: Array<IAssetAutoCompleteField<string>>
}

const optionsReligion: Array<IAssetAutoCompleteField<religionTypes>> = [
  {
    label: "catholic",
    value: "catholic"
  },
  {
    label: "muslim",
    value: "muslim"
  },
  {
    label: "jewish",
    value: "jewish"
  }
];

export const createdeceasedForm: (
  {
    onChangeAddress,
    optionsAddress
  } : ICreateDeceasedForm) => Array<IForm> = ({
    onChangeAddress,
    optionsAddress,
  }: ICreateDeceasedForm) => {
    return [
      {
        type: "DatePicker",
        elementDatePicker: {
          name: "dateOfBirth",
          label: "dateOfBirth",
        }
      },
      {
        type: "DatePicker",
        elementDatePicker: {
          name: "dateOfDeath",
          label: "dateOfDeath",
        }
      },
      {
        type: "TextField",
        elementTextField: {
          id: "name",
          type: "text",
          label: "name",
          name: "name"
        }
      },
      {
        type: "TextField",
        elementTextField: {
          id: "firstName",
          name: "firstName",
          label: "firstName",
          type: "text"
        }
      },
      {
        type: "Autocomplete",
        elementAutoComplete: {
          id: "lastAddress",
          label: "lastAddress",
          name: "lastAddress",
          options: optionsAddress,
          onChangeTextField: (data) => onChangeAddress(data)
        }
      },
      {
        type: "Autocomplete",
        elementAutoComplete: {
          required: false,
          id: "religion",
          label: "religion",
          name: "religion",
          options: optionsReligion
        }
      }
    ];
  };

export interface IDeceasedForm {
  dateOfBirth: Date;
  dateOfDeath: Date;
  name: string;
  firstName: string;
  lastAddress: string;
  religion?: IAssetAutoCompleteField<religionTypes>
}
import React, {ChangeEvent, useState, useEffect} from "react";

// Ui
import { Typography, Box } from "@mui/material";
import { DataGrid, GridRowsProp } from "@mui/x-data-grid";
import { LoadingButton } from "@mui/lab";

// I18n
import { useTranslation } from "react-i18next";

// Lib
import * as XLSX from "xlsx";
import { verifyKeyOfData } from "../../utils/verifykeyofdata";

// Hooks
import { useAlert } from "../../provider/error/hooks/useAlert";

// Type
import { IDataFrontUploadStock } from "type-absenso";

// Backend
import { postAuth } from "../../backend/queries/config";
import { addStockUrl } from "../../backend/queries/url";

const keys = ["product_id", "label_id"];

const UploadStock = () => {
  const {t} = useTranslation();
  const alert = useAlert();

  const [file, setFile] = useState<ChangeEvent<HTMLInputElement>>();
  const [data, setData] = useState<Array<IDataFrontUploadStock>>();
  const [loading, setLoading] = useState<boolean>();
  const [rows, setRows] = useState<GridRowsProp>();
  
  useEffect(() => {
    (async() => {
      if (file && file.target.files && file.target.files.length > 0) {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file.target.files[0]);
        fileReader.onload = (e) => {
          const a = XLSX.read(e.target?.result, {
            type: "buffer",
            cellDates: true
          });
          const b = a.SheetNames[0];
          const c = a.Sheets[b];
          const data: Array<{[key: string]: unknown}> = 
            XLSX.utils.sheet_to_json(c, {"defval": ""});
          setData(data as unknown as Array<IDataFrontUploadStock>);
          const dataForGrid: GridRowsProp = data.map((element, i) => {
            return {...element, id: i};
          });
          if (verifyKeyOfData(data, keys)) {
            setRows(dataForGrid);
          } else {
            alert?.createAlert({
              type: "error",
              message: t("excelErrorType")
            });
          }
        };
      }
    })();
  }, [file]);

  const submit = async () => {
    setLoading(true);
    if (data) {
      try {
        await postAuth(addStockUrl, data);
        alert?.createAlert({
          type: "success",
          message: t("stockUploadSuccess")
        });
        setRows(undefined);
        setData(undefined);
      } catch (error) {
        alert?.createAlert({
          type: "error",
          message: t("errorStandard")
        });
      }
    }
    setLoading(false);
  };

  return(
    <>
      <Typography component="h4" variant="h4">
        {t("uploadStockTitle")}
      </Typography>

      <Box pt={2}>
        <input 
          type="file"
          accept=".xlsx,.xls"
          onChange={(e) => setFile(e)}
        />

        {rows && 
            <Box pt={2}>
              <DataGrid 
                autoHeight
                rows={rows || []}
                columns={keys.map(
                  element => {
                    return {headerName: element, field: element, width: 200};
                  }
                )}
              />

              <Box pt={2} sx={{
                width: "100%"
              }}>
                <LoadingButton
                  loading={loading}
                  onClick={submit}
                  variant="contained" 
                  sx={{
                    width: "100%"
                  }}>
                  {t("add")}
                </LoadingButton>
              </Box>
            </Box>
        }
      </Box>
    </>
  );
};

export default UploadStock;

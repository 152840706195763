export const routing = {
  dashboard: {
    index: "/dashboard"
  },
  auth: {
    signin: "/auth/signin"
  },
  createUser: {
    index: "/create/user",
    adminUser: "/create/user/admin",
    funeralUser: "/create/user/funeral"
  },
  uploadStock: {
    index: "/upload/stock"
  },
  transferStock: {
    index: "/transfer/stock"
  },
  addProduct: {
    index: "/add/product"
  },
  createKit: {
    index: "/create/kit"
  },
  seeKit: "/see/kit",
  deceasedPage: {
    create: "/deceased/create/page",
    linkAdmin: "/deceased/link/admin",
    handle: "/deceased/handle",
    handleForm: "/deceased/handle/:id"
  }
};
import { List, ListItem, ListItemText, Typography } from "@mui/material";
import React from "react";
import { useMemo } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { searchKitWithId } from "../../backend/queries/stock";
import { IKitFront } from "../../backend/queries/types/frontDocument";
import FormComponent from "../../components/forms/formcomponent";
import { IAssetAutoCompleteField } from "../../components/forms/types";

const SeeKit = () => {
  const form = useForm();
  const {t} = useTranslation();

  const [options, setOptions] = useState<
    Array<IAssetAutoCompleteField<IKitFront>>
    >([]);

  const kitValueForm = useMemo<
    IAssetAutoCompleteField<IKitFront> | undefined
  >(() => {
    return form.watch("searchKit") as IAssetAutoCompleteField<IKitFront>;
  }, [form.watch("searchKit")]);

  console.log(kitValueForm && kitValueForm.value);

  const onChangeHandler = async (data: string) => {
    try {
      const kits = await searchKitWithId(data);
      const opts: Array<IAssetAutoCompleteField<IKitFront>> = kits.map(k => {
        return {
          label: k.id,
          value: k
        };
      });

      setOptions(opts);
    } catch (error) {
      console.log(error);
    }
  };

  return(
    <>
      <FormComponent
        watch={form.watch}
        formState={form.formState}
        control={form.control}
        arrayForms={[
          {
            type: "Autocomplete",
            elementAutoComplete: {
              name: "searchKit",
              id: "searchKit",
              options: options,
              label: "searchKit",
              onChangeTextField: onChangeHandler
            }
          }
        ]}
      />

      {kitValueForm && <>
        <Typography component="h1" sx={{mt: 2}}>
          {t("associatedProductInKit", {
            kitId: kitValueForm.label
          })}
        </Typography>
        <List>
          {kitValueForm.value.products.map((p, i) => (
            <ListItem key={i}>
              <ListItemText>{p.id}</ListItemText>
            </ListItem>
          ))}
        </List>
      </>}
    </>
  );
};

export default SeeKit;

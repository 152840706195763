import { IUsers, collections, IDataFrontFuneralUser } from "type-absenso";

// Firestore
import { db } from "../config";
import { doc, getDoc, limit } from "@firebase/firestore";

// Help
import { postAuth } from "./config";
import { createAdminUrl, createFuneralUrl } from "./url";

// Type
import {IDataFrontAdminUser} from "type-absenso";
import {
  collection,
  getDocs,
  query,
  Timestamp,
  where
} from "firebase/firestore";
import { IFrontUser } from "./types/frontDocument";

export const getCurrentUser = async ({
  errorAction,
  uid,
}: {
    errorAction: () => void;
    uid: string;
}) => {
  try {
    const userRef = doc(db, collections.users, uid);
    const snap = await getDoc(userRef);

    return snap.data() as IUsers<Timestamp> | undefined;
  } catch (error) {
    errorAction();
  }
};

export const createAdminUser = async ({
  data,
  errorAction,
  successAction,
}: {
  data: IDataFrontAdminUser,
  errorAction: () => void,
  successAction: () => void,
}) => {
  try {
    await postAuth(createAdminUrl, data);
    successAction();
  } catch (error) {
    errorAction();
  }
};

export const createFuneralUser = async({
  errorAction,
  successAction,
  data
}: {
  data: IDataFrontFuneralUser,
  errorAction: () => void;
  successAction: () => void;
}) => {
  try {
    await postAuth(createFuneralUrl , data);
    successAction();
  } catch (error) {
    errorAction();
  }
};

export const searchUser = async ({
  errorAction,
  search
}: {
  errorAction: () => void;
  search: string;
}) => {
  try {
    const dataUser: Array<IFrontUser> = [];
    const userRef = collection(db, collections.users);

    const q = query(
      userRef,
      where("email", ">=", search),
      where("email", "<=", search+"\uf8ff"),
      limit(10)
    );
    const snaps = await getDocs(q);

    for await (const snap of snaps.docs) {
      const data = snap.data() as IUsers<Timestamp>;
      dataUser.push({
        ...data,
        id: snap.id,
      });

      return dataUser;
    }
  } catch (error) {
    errorAction();
  }
};
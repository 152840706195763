import { IAssetAutoCompleteField, IForm } from "../../types";
import { usersTypes, TSexe } from "type-absenso";

const optionsRole: Array<IAssetAutoCompleteField<usersTypes>> = [
  {label: "adminAdmin", value: "adminAdmin"},
  {label: "userAdmin", value: "userAdmin"}
];

export interface IAdminUserForm {
    email: string;
    firstName: string;
    name: string;
    role: IAssetAutoCompleteField<usersTypes>;
    sexe: IAssetAutoCompleteField<TSexe>;
    phone: string;
}

export const createUserAdminForm: Array<IForm> = [
  {
    type: "TextField",
    elementTextField: {
      id: "name",
      type: "text",
      label: "name",
      name: "name"
    }
  },
  {
    type: "TextField",
    elementTextField: {
      id: "firstName",
      name: "firstName",
      label: "firstName",
      type: "text"
    }
  },
  {
    type: "TextField",
    elementTextField: {
      id: "email",
      name: "email",
      label: "email",
      type: "text",
    }
  },
  {
    type: "TextField",
    elementTextField: {
      id: "phone",
      name: "phone",
      label: "phone",
      type: "text",
    }
  },
  {
    type: "Autocomplete",
    elementAutoComplete: {
      name: "role",
      label: "role",
      id: "role",
      options: optionsRole,
    }
  },
  {
    type: "Autocomplete",
    elementAutoComplete: {
      name: "sexe",
      label: "sexe",
      id: "sexe",
      options: [
        {label: "woman", value: "woman"},
        {label: "boy", value: "boy"},
        {label: "other", value: "other"}
      ],
    }
  }
];
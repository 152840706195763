import { IForm } from "../../types";

export const addProductForm: Array<IForm> = [
  {
    type: "TextField",
    elementTextField: {
      id: "name",
      name: "name",
      type: "text",
      label: "name"
    }
  },
  {
    type: "TextField",
    elementTextField: {
      id: "price",
      name: "price",
      type: "number",
      label: "price"
    }
  }
];
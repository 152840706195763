import { urls } from "type-absenso";

let proxy = "";

if (process.env.NODE_ENV === "development") {
  proxy = "http://localhost:5001/stagging-absenso/europe-west1/api";
} else {
  proxy = process.env.REACT_APP_BACKEND_URL as string;
}

export const createAdminUrl = `${proxy}${urls.createAdminUser}`;
export const createFuneralUrl = `${proxy}${urls.createFuneralUser}`;
export const addressFormatUrl = `${proxy}${urls.formatAddress}`;
export const detailsAddressUrl = `${proxy}${urls.detailsAddress}`;
export const sendMailUrl = `${proxy}${urls.sendMail}`;
export const addProductUrl = `${proxy}${urls.addProduct}`;
export const addStockUrl = `${proxy}${urls.addStock}`;
export const createKitUrl = `${proxy}${urls.createKit}`;
export const transferUrl = `${proxy}${urls.transferStock}`;
export const transferKitUrl = `${proxy}${urls.transferKit}`;
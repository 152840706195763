import React from "react";
import { RemoveRedEye } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";

import { Link } from "react-router-dom";
import { routing } from "../../router/config";

export const arrayDeathColumn = [
  "firstName",
  "name",
  "fullname",
  "lastAddress",
  "dateOfDeath",
  "dateOfBirth"
];

export const columnDeath: Array<GridColDef> = [...arrayDeathColumn.map(
  element => {
    return {field: element, headerName: element, width: 150};
  }
), {
  field: "action",
  headerName: "Action(s)",
  width: 150,
  renderCell: (params: GridRenderCellParams) => {
    return (
      <Link to={`${routing.deceasedPage.handleForm.replace(
        ":id", params.row.id
      )}`}>
        <IconButton color="primary" component="span">
          <RemoveRedEye />
        </IconButton>
      </Link>
    );
  }
}];
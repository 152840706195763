/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";

// Router
import { Params, useParams } from "react-router-dom";

// Backend
import { getOneDeath, updateDeath } from "../../backend/queries/death";
import { Timestamp } from "@firebase/firestore";

// Type
import { IFrontDeath } from "../../backend/queries/types/frontDocument";
import { IAssetAutoCompleteField } from "../../components/forms/types";
import { IGeolocation, collections } from "type-absenso";
import { IDeceasedForm } from "../../components/forms/asset/deceasded/create";

// Ui
import { CircularProgress, Box, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

// Form
import FormComponent from "../../components/forms/formcomponent";
import { createdeceasedForm }
  from "../../components/forms/asset/deceasded/create";
import { useForm } from "react-hook-form";

// Backend
import {
  getStorage,
  uploadBytesResumable,
  getDownloadURL,
  ref
} from "@firebase/storage";
import { formatAdress } from "../../backend/queries/address";
import { getAddressDetails } from "../../backend/queries/address";
import { app } from "../../backend/config";

// Hooks
import { useAlert } from "../../provider/error/hooks/useAlert";
import { useTranslation } from "react-i18next";

// Component
import ImageCropper from "../../components/utils/imageCropper";

// Lib
import { geohashForLocation } from "geofire-common";
import { uid } from "uid";

const HandleForm = () => {
  const {id}: Readonly<Params<string>> = useParams();
  const {t} = useTranslation();
  const {watch, formState, control, setValue, handleSubmit} = useForm();
  const alert = useAlert();

  const [userData, setUserData] = useState<IFrontDeath>();
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>();

  const [urlCrop, setUrlCrop] = useState<string>();
  const [crop, setCrop] = useState<File>();
  const [formattedAddress, setFormattedAddress] = useState<string>();
  const [geocode, setGeocode] = useState<IGeolocation>();
  const [optionsAdress, setOptionsAddress] =
    useState<Array<IAssetAutoCompleteField<string>>>();

  const onChangeAddress = async (data: string) => {
    const addresses = await formatAdress({
      errorAction: () => alert?.createAlert({
        type: "error",
        message: t("errorStandard")
      }),
      data,
    });

    if (addresses) {
      setOptionsAddress(addresses.map(elt => {
        return {label: elt.description, value: elt.place_id};
      }));
    }

  };

  useEffect(() => {
    if (id) {
      (async() => {
        setLoading(true);
        const data = await getOneDeath({
          errorAction: () => false,
          id,
        });
        setValue("dateOfBirth", data?.dateOfBirth.toDate());
        setValue("dateOfDeath", data?.dateOfDeath.toDate());
        setValue("name", data?.name);
        setValue("firstName", data?.firstName);
        setValue("lastAddress", {
          label: data?.lastAddress,
          value: data?.lastAddress,
        });
        setValue("religion", {
          label: data?.religion,
          value: data?.religion
        });
        setUserData(data);
        setLoading(false);
      })();
    }
  }, [id]);

  useEffect(() => {
    if (watch("lastAddress")) {
      (async() => {
        const data = watch("lastAddress") as IAssetAutoCompleteField<string>;
        if (data.label !== data.value) {
          const details = await getAddressDetails({
            errorAction: () => alert?.createAlert({
              type: "error",
              message: t("errorStandard")
            }),
            placeId: data.value
          });

          if (details?.result.geometry.location) {
            const hash = geohashForLocation([
              details?.result.geometry.location.lat,
              details?.result.geometry.location.lng
            ]);
            setFormattedAddress(details?.result.formatted_address);
            setGeocode({
              ...details?.result.geometry.location,
              hash,
            });
          }
        }
      })();
    }
  }, [watch("lastAddress")]);

  const onSubmit = async (data: unknown) => {
    if (id) {
      setLoadingSubmit(true);
      try {
        const dataForm = data as IDeceasedForm;

        const dataToSend: any = {
          name: dataForm.name,
          firstName: dataForm.firstName,
          fullname: `${dataForm.firstName} ${dataForm.name}`,
          dateOfBirth: Timestamp.fromDate(dataForm.dateOfBirth),
          dateOfDeath: Timestamp.fromDate(dataForm.dateOfDeath),
          religion: dataForm.religion?.value || null
        };

        if (crop) {
          const storage = getStorage(app);
          const deathImgRef = ref(
            storage, 
            `${collections.death}/${uid(32)}`
          );
        
          await uploadBytesResumable(
            deathImgRef,
            crop,
          );
          const url = await getDownloadURL(deathImgRef);
          dataToSend["profilePicture"] = url;
        }

        if (geocode && formattedAddress) {
          dataToSend["geoLocation"] = geocode;
          dataToSend["lastAddress"] = formattedAddress;
        }

        await updateDeath({
          id,
          data: dataToSend,
        });

        setFormattedAddress(undefined);
        setGeocode(undefined);
        setCrop(undefined);
        setUrlCrop(undefined);
        alert?.createAlert({
          type: "success",
          message: t("successDesceaded")
        });
      } catch (error) {
        alert?.createAlert({
          type: "error",
          message: t("errorStandard")
        });
      }
      setLoadingSubmit(false);
    }
  };

  return(
    <>
      {loading && 
        <Box sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "80vh",
        }}>
          <CircularProgress />
        </Box>
      }

      {userData &&
        <>
          <Typography sx={{
            pb: 1
          }} component="h4" variant="h4">
            {userData?.fullname}
          </Typography>

          <Box pt={1}>
            <ImageCropper 
              urlCrop={urlCrop}
              setUrlCrop={setUrlCrop}
              onCrop={(crop) => setCrop(crop)}
            />

            <Box pt={2}>
              <FormComponent 
                watch={watch}
                formState={formState}
                control={control}
                arrayForms={createdeceasedForm({
                  onChangeAddress,
                  optionsAddress: optionsAdress || [],
                })
                }
              />
            </Box>

            <Box pt={2} sx={{width: "100%"}}>
              <LoadingButton
                loading={loadingSubmit}
                onClick={handleSubmit(onSubmit)}
                variant="contained"
                sx={{width: "100%"}}
              >
                {t("change")}
              </LoadingButton>
            </Box>
          </Box>
        </>
      }
    </>
  );
};

export default HandleForm;

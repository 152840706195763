import React from "react";
import LayoutAdmin from "../../views/layout/layoutadmin";

// Asset
import { arrayMenuAdminAdmin } from "./asset/accesslayout";

// Router
import { Route, Routes, Navigate } from "react-router-dom";
import { routing } from "../config";

// Views
import Home from "../../views/user/home";
import AdminUser from "../../views/user/adminuser";
import FuneralUser from "../../views/user/funeraluser";
import CreateDeaceased from "../../views/deceased/createdeaceased";
import LinkAdmin from "../../views/deceased/linkadmin";
import HandleHome from "../../views/deceased/handlehome";
import HandleForm from "../../views/deceased/handleForm";
import AddProduct from "../../views/stock/addProduct";
import UploadStock from "../../views/stock/uploadStock";
import CreateKit from "../../views/stock/createKit";
import TransferStock from "../../views/stock/transferStock";
import DashboardHome from "../../views/dashboard/dashboardHome";
import SeeKit from "../../views/stock/seeKit";

const AuthenticatedRouteAdmin = () => {
  return(
    <LayoutAdmin arrayMenu={arrayMenuAdminAdmin}>
      <Routes>
        <Route 
          path="/"
          element={<Navigate to={routing.dashboard.index} />}
        />
        <Route 
          path={routing.dashboard.index}
          element={<DashboardHome />}
        />
        <Route
          path={routing.createUser.index}
          element={<Home />}
        />
        <Route
          path={routing.createUser.adminUser}
          element={<AdminUser />}
        />
        <Route 
          path={routing.createUser.funeralUser}
          element={<FuneralUser />}
        />
        <Route 
          path={routing.deceasedPage.create}
          element={<CreateDeaceased />}
        />
        <Route 
          path={routing.seeKit}
          element={<SeeKit />}
        />
        <Route 
          path={routing.deceasedPage.linkAdmin}
          element={<LinkAdmin />}
        />
        <Route 
          path={routing.deceasedPage.handle}
          element={<HandleHome />}
        />
        <Route 
          path={routing.deceasedPage.handleForm}
          element={<HandleForm />}
        />
        <Route 
          path={routing.addProduct.index}
          element={<AddProduct />}
        />
        <Route 
          path={routing.uploadStock.index}
          element={<UploadStock />}
        />
        <Route 
          path={routing.createKit.index}
          element={<CreateKit />}
        />
        <Route 
          path={routing.transferStock.index}
          element={<TransferStock />}
        />
      </Routes>
    </LayoutAdmin>
  );
};

export default AuthenticatedRouteAdmin;

import React, {useState} from "react";

// Ui
import { Typography, Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";

// I18n
import { useTranslation } from "react-i18next";

// Form
import FormComponent from "../../components/forms/formcomponent";
import { useForm } from "react-hook-form";
import { createFuneralUser } from "../../components/forms/asset/user/funeral";

// Backend
import {
  createFuneralUser as createFuneralUserQuery
} from "../../backend/queries/users";

// Type
import {collections, IDataFrontFuneralUser} from "type-absenso";
import { useAlert } from "../../provider/error/hooks/useAlert";
import ImageCropper from "../../components/utils/imageCropper";
import { uploadFile } from "../../utils/uploadFile";

const FuneralUser = () => {
  const {t} = useTranslation();
  const {watch, formState, control, handleSubmit} = useForm();
  const alert = useAlert();

  const [loading, setLoading] = useState<boolean>();

  const [urlCrop, setUrlCrop] = useState<string>();
  const [crop, setCrop] = useState<File>();

  const onSubmit = async (data: unknown) => {
    setLoading(true);
    const dataForm = data as IDataFrontFuneralUser;

    if (crop) {
      const urlPictures = await uploadFile({
        file: crop,
        collection: collections.funeralCompany
      });
      dataForm["pictures"] = urlPictures;
    } else {
      dataForm["pictures"] = null;
    }

    await createFuneralUserQuery({
      successAction: () => alert?.createAlert({
        type: "success",
        message: t("successUserAdmin")
      }),
      errorAction: () => alert?.createAlert({
        type: "error",
        message: t("errorStandard")
      }),
      data: dataForm,
    });
    setLoading(false);
  };

  return(
    <>
      <Typography variant="h4" component="h4">
        {t("addFuneralAccount")}
      </Typography>

      <Box pt={2}>
        <Box py={2}>
          <ImageCropper 
            urlCrop={urlCrop}
            setUrlCrop={setUrlCrop}
            onCrop={(crop) => setCrop(crop)}
          />
        </Box>
        <FormComponent 
          watch={watch}
          formState={formState}
          control={control}
          arrayForms={createFuneralUser}
        />

        <Box pt={2} sx={{width: "100%"}}>
          <LoadingButton 
            sx={{width: "100%"}}
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            loading={loading}
          >
            {t("create")}
          </LoadingButton>
        </Box>
      </Box>
    </>
  );
};

export default FuneralUser;
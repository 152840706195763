import React from "react";
import LayoutAdmin from "../../views/layout/layoutadmin";

// Asset
import { arrayMenuAdminUser } from "./asset/accesslayout";
import { Route, Routes } from "react-router-dom";
import { routing } from "../config";
import TransferStock from "../../views/stock/transferStock";
import CreateDeaceased from "../../views/deceased/createdeaceased";
import LinkAdmin from "../../views/deceased/linkadmin";
import HandleHome from "../../views/deceased/handlehome";
import HandleForm from "../../views/deceased/handleForm";

const AuthenticatedRouteAdminUser = () => {
  return(
    <LayoutAdmin arrayMenu={arrayMenuAdminUser}>
      <Routes>
        <Route 
          path={routing.transferStock.index}
          element={<TransferStock />}
        />
        <Route 
          path={routing.deceasedPage.create}
          element={<CreateDeaceased />}
        />
        <Route 
          path={routing.deceasedPage.linkAdmin}
          element={<LinkAdmin />}
        />
        <Route 
          path={routing.deceasedPage.handle}
          element={<HandleHome />}
        />
        <Route 
          path={routing.deceasedPage.handleForm}
          element={<HandleForm />}
        />
      </Routes>
    </LayoutAdmin>
  );
};

export default AuthenticatedRouteAdminUser;

import React, { useEffect, useState } from "react";

// Ui
import { Typography, Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import { columnDeath } from "../../utils/assetDataGrid/death";

// I18n
import { useTranslation } from "react-i18next";

// Backend
import { getAllDeathInMyResponsability } from "../../backend/queries/death";

// Type
import { IFrontDeath } from "../../backend/queries/types/frontDocument";
import { useAlert } from "../../provider/error/hooks/useAlert";

const HandleHome = () => {
  const {t} = useTranslation();
  const alert = useAlert();
  const [rows, setRows] = useState<Array<IFrontDeath>>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    (async() => {
      setLoading(true);
      const data = await getAllDeathInMyResponsability({
        errorAction: () => alert?.createAlert({
          type: "error",
          message: t("errorStandard")
        }),
      });

      setRows(data);
      setLoading(false);
    })();
  }, []);
  
  return(
    <>
      <Typography component="h4" variant="h4">
        {t("handleDeceased")}
      </Typography>

      <Box pt={2}>
        <DataGrid 
          loading={loading}
          autoHeight
          rows={rows || []}
          columns={columnDeath || []}
        />
      </Box>
    </>
  );
};

export default HandleHome;

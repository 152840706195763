import React from "react";

// Ui
import { AppBar, Container, Typography, Box } from "@mui/material";

// I18n
import { useTranslation } from "react-i18next";
import { darkTurquoise } from "../../ui/color";

const LayoutUnConnected: React.FC = ({children}) => {
  const {t} = useTranslation();
  return(
    <>
      <AppBar position="static" sx={{
        background: darkTurquoise
      }}>
        <Box py={2}>
          <Container maxWidth="xl">
            <Typography
              variant="h6"
              noWrap
              component="div"
            >
              {t("title")}
            </Typography>
          </Container>
        </Box>
      </AppBar>

      <Container>
        {children}
      </Container>
    </>
  );
};

export default LayoutUnConnected;

import { GridColDef } from "@mui/x-data-grid";

export const stockArrayColumn = [
  "idLabel",
  "place",
  "product"
];

export const columnStock: Array<GridColDef> = stockArrayColumn.map(
  element => {
    return {field: element, headerName: element, width: 150};
  }
);

export const kitArrayColumn = [
  "id",
  "products",
  "place"
];

export const columnKit: Array<GridColDef> = kitArrayColumn.map(
  element => {
    return {field: element, headerName: element, width: 150};
  }
);
import React from "react";

// Ui
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";

// I18n
import { useTranslation } from "react-i18next";

// Form
import { useForm } from "react-hook-form";
import FormComponent from "../../forms/formcomponent";

// Backend
import { getAuth, sendPasswordResetEmail } from "@firebase/auth";
import { app } from "../../../backend/config";

// Hooks
import { useAlert } from "../../../provider/error/hooks/useAlert";

export const ForgotPassword = ({
  open,
  setOpen,
}: {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const {t} = useTranslation();
  const {watch, formState, control, handleSubmit} = useForm();
  const alert = useAlert();

  const onSubmit = async (data: unknown) => {
    try {
      const dataForm = data as {email: string};
      const auth = getAuth(app);
      await sendPasswordResetEmail(auth, dataForm.email);
      alert?.createAlert({
        type: "success",
        message: t("emailSent")
      });
      setOpen(false);
    } catch (error) {
      alert?.createAlert({
        type: "error",
        message: t("errorStandard")
      });
    }
  };

  return(
    <>
      <Dialog
        open={open}
        fullWidth={true}
        onClose={() => setOpen(false)}
      >
        <DialogTitle>
          {t("forgotPass")}
        </DialogTitle>
        <DialogContent>
          <FormComponent 
            watch={watch}
            formState={formState}
            control={control}
            arrayForms={[
              {
                type: "TextField",
                elementTextField: {
                  id: "email",
                  name: "email",
                  label: "email",
                  type: "text"
                }
              }
            ]}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleSubmit(onSubmit)}>
            {t("change")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
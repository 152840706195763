import { IForm } from "../../types";

export const createFuneralUser: Array<IForm> = [
  {
    type: "TextField",
    elementTextField: {
      id: "name",
      type: "text",
      label: "name",
      name: "name"
    }
  },
  {
    type: "TextField",
    elementTextField: {
      id: "firstName",
      name: "firstName",
      label: "firstName",
      type: "text"
    }
  },
  {
    type: "TextField",
    elementTextField: {
      id: "email",
      name: "email",
      label: "email",
      type: "text",
    }
  },
  {
    type: "TextField",
    elementTextField: {
      id: "phone",
      name: "phone",
      label: "phone",
      type: "text"
    }
  },
  {
    type: "TextField",
    elementTextField: {
      id: "companyName",
      name: "companyName",
      label: "companyName",
      type: "text"
    }
  },
  {
    type: "TextField",
    elementTextField: {
      id: "headOffice",
      name: "headOffice",
      label: "headOffice",
      type: "text"
    }
  },
  {
    type: "TextField",
    elementTextField: {
      id: "postalCodeHeadOffice",
      name: "postalCodeHeadOffice",
      label: "postalCodeHeadOffice",
      type: "text"
    }
  },
  {
    type: "TextField",
    elementTextField: {
      id: "cityHeadOffice",
      name: "cityHeadOffice",
      label: "cityHeadOffice",
      type: "text"
    }
  },
  {
    type: "TextField",
    elementTextField: {
      id: "countryHeadOffice",
      name: "countryHeadOffice",
      label: "countryHeadOffice",
      type: "text"
    }
  },
  {
    type: "TextField",
    elementTextField: {
      id: "vatNumber",
      name: "vatNumber",
      label: "vatNumber",
      type: "text"
    }
  },
  {
    type: "TextField",
    elementTextField: {
      id: "deliveryAddress",
      name: "deliveryAddress",
      label: "deliveryAddress",
      type: "text"
    }
  },
  {
    type: "TextField",
    elementTextField: {
      id: "postalCode",
      name: "postalCode",
      label: "postalCode",
      type: "text"
    }
  },
  {
    type: "TextField",
    elementTextField: {
      id: "city",
      name: "city",
      label: "city",
      type: "text"
    }
  },
  {
    type: "TextField",
    elementTextField: {
      id: "country",
      name: "country",
      label: "country",
      type: "text"
    }
  },
  {
    type: "TextField",
    elementTextField: {
      multiline: true,
      name: "description",
      label: "description",
      type: "text",
      rows: 3,
      id: "description",
      required: false
    }
  }
];
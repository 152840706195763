/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";

// Ui
import {
  Typography,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  ListItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

// I18n
import { useTranslation } from "react-i18next";

// Hooks
import { useAlert } from "../../provider/error/hooks/useAlert";

// Backend
import { postAuth } from "../../backend/queries/config";
import { createKitUrl } from "../../backend/queries/url";

// Type
import { IDataFrontCreateKit } from "type-absenso";
import FormComponent from "../../components/forms/formcomponent";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { AxiosError } from "axios";

// @ts-ignore
import QrReader from "react-qr-scanner";

const CreateKit = () => {
  const {t} = useTranslation();
  const alert = useAlert();
  const formKit = useForm();
  const formProduct = useForm();

  const [idBoxState, setIdBox] = useState<string>();
  const [idsProducts, setIdProducts] = useState<Array<string>>([]);

  const [loading, setLoading] = useState<boolean>();

  useEffect(() => {
    const kitNameReplica: string | undefined = formKit.watch("kitName");
    if (kitNameReplica && kitNameReplica.length === 2) {
      formKit.setValue("kitName", `${kitNameReplica}-`);
    } else if (kitNameReplica && kitNameReplica.length === 4) {
      formKit.setValue("kitName", `${kitNameReplica}-`);
    } else if (kitNameReplica && kitNameReplica.length > 10) {
      formKit.setValue("kitName", kitNameReplica.substring(0, 10));
    }
  } , [formKit.watch("kitName")]);

  useEffect(() => {
    const productNameReplica: string | undefined = formProduct.watch(
      "productName"
    );
    if (productNameReplica && productNameReplica.length === 2) {
      formProduct.setValue("productName", `${productNameReplica}-`);
    } else if (productNameReplica && productNameReplica.length === 4) {
      formProduct.setValue("productName", `${productNameReplica}-`);
    } else if (productNameReplica && productNameReplica.length > 10) {
      formProduct.setValue("productName", productNameReplica.substring(0, 10));
    }
  } , [formProduct.watch("productName")]);

  const onResult = (result: null | string) => {
    if (result && result.length >= 10) {
      const arrayText = result.split("/");
      const idBox = arrayText[arrayText.length - 1];
      setIdBox(idBox);
    } else {
      formKit.setError("kitName", {});
    }
  };

  const onResultProduct = (result: null | string) => {
    if (result && result.length >= 10) {
      const arrayText = result.split("/");
      const idProduct = arrayText[arrayText.length - 1];
      setIdProducts([...idsProducts, idProduct]);
      formProduct.reset();
    } else {
      formProduct.setError("productName", {});
    }
  };

  const createKitHandler = () => {
    if (formKit.watch("kitName")) {
      onResult(`/${(formKit.watch("kitName") as string).toUpperCase()}`);
    }
  };

  const createProductHandler = () => {
    if (formProduct.watch("productName")) {
      onResultProduct(`/${formProduct.watch("productName")}`);
    }
  };

  const submit = async () => {
    setLoading(true);
    if (idsProducts && idBoxState) {
      try {
        const data: IDataFrontCreateKit = {
          products: idsProducts.map(p => p.toUpperCase()),
          idBox: idBoxState.toUpperCase()
        };
        await postAuth(createKitUrl, data);
        alert?.createAlert({
          type:"success",
          message: t("kitcreated")
        });
        setIdBox(undefined);
        setIdProducts([]);
      } catch (error: any) {
        const errorRes: AxiosError = error;
        if (
          errorRes.response?.data &&
          errorRes.response?.data.productIsntExist
        ) {
          alert?.createAlert({
            type: "error",
            message: t("productIsntExist", {
              uid: errorRes.response?.data.productIsntExist
            })
          });
        } else {
          alert?.createAlert({
            type: "error",
            message: t("errorStandard")
          });
        }
      }
    }
    setLoading(false);
  };

  return(
    <>
      <Box pt={2} sx={{
        width: "100%",
        height: "100vh"
      }}>
        <QrReader
          delay={1000}
          style={{
            width: "100%",
          }}
          onScan={onResultProduct}
          onError={() => false}
        />
      </Box>
      <Box sx={{
        display: "flex",
        justifyContent: "space-between",
      }}>
        <Typography component="h4" variant="h4">
          {t("createKit")}
        </Typography>

        <Button variant="contained" onClick={() => {
          formKit.reset();
          formProduct.reset();
          setIdProducts([]);
        }}>
          {t("resetForm")}
        </Button>
      </Box>

      <Box sx={{pt: 2}}>
        <FormComponent 
          watch={formKit.watch}
          control={formKit.control}
          formState={formKit.formState}
          arrayForms={[{
            type: "TextField",
            elementTextField: {
              name: "kitName",
              id: "kitName",
              type: "text",
              label: "kitName"
            }
          }]}
        />

        <Button
          variant="contained"
          onClick={createKitHandler}
          disabled={!formKit.watch("kitName")}
        >
          {t("createKit")}
        </Button>
      </Box>

      <Dialog 
        open={Boolean(idBoxState)}
        onClose={() => setIdBox(undefined)}
        fullWidth={true}
      >
        <DialogTitle>{t("scanProduct", {box: idBoxState})}</DialogTitle>

        <DialogContent>
          <FormComponent 
            watch={formProduct.watch}
            formState={formProduct.formState}
            control={formProduct.control}
            arrayForms={[{
              type: "TextField",
              elementTextField: {
                name: "productName",
                id: "productName",
                type: "text",
                label: "productName"
              }
            }]}
          />

          <Button
            variant="contained"
            onClick={createProductHandler}
            disabled={!formProduct.watch("productName")}
            sx={{
              width: "100%"
            }}>
            {t("addProduct")}
          </Button>
          {idsProducts.map((elt, i) => (
            <ListItem key={i}>
              {elt}
            </ListItem>
          ))}
        </DialogContent>
        {idsProducts.length > 0 && <DialogActions>
          <LoadingButton
            loading={loading}
            onClick={submit}
            variant="contained"
          >
            {t("addKit")}
          </LoadingButton>
        </DialogActions>}
      </Dialog>

    </>
  );
};

export default CreateKit;

import {
  collection,
  doc,
  DocumentReference,
  getDoc,
  getDocs,
  query,
  where
} from "firebase/firestore";
import { collections, IKit, IProduct, IStock } from "type-absenso";
import { db } from "../config";
import { IKitFront, IStockFront } from "./types/frontDocument";

export const getSTockWithoutPlace = async ({
  errorAction,
}: {
    errorAction: () => void;
}) => {
  try {
    const dataStock: Array<IStockFront> = [];
    const stockRef = collection(db, collections.stock);
    const q = query(
      stockRef,
      where("place", "==", "absenso")
    );
    const snaps = await getDocs(q);
    for await (const snap of snaps.docs) {
      const data = snap.data() as IStock<DocumentReference> | undefined;
      if (data) {
        const productSnap = await getDoc(doc(
          db,
          data.product.path,
        ));
        const dataProduct = productSnap.data() as IProduct | undefined;
  
        if (dataProduct) {
          dataStock.push({
            ...data,
            id: snap.id,
            product: dataProduct.name
          });
        }
      }
    }

    return dataStock;
  } catch (error) {
    errorAction();
  }
};

export const getKitWithoutPlace = async({
  errorAction
}: {
    errorAction: () => void
}) => {
  try {
    const dataKit: Array<IKitFront> = [];
    const kitRef = collection(db, collections.kit);
    const q = query(
      kitRef,
      where("place", "==", "absenso")
    );
    const snaps = await getDocs(q);
    for await (const snap of snaps.docs) {
      const data = snap.data() as IKit<DocumentReference>;
      dataKit.push({...data, id: snap.id});
    }

    return dataKit;
  } catch (error) {
    errorAction();
  }
};

export const searchKitWithId = async(id: string) => {
  const dataKit: Array<IKitFront> = [];
  const kitRef = collection(db, collections.kit);
  const q = query(
    kitRef,
    where("__name__", ">=", id.toUpperCase()),
    where("__name__", "<=", id.toLowerCase()+"\uf8ff"),
  );
  const snaps = await getDocs(q);
  for await (const snap of snaps.docs) {
    const data = {...snap.data(), id: snap.id} as IKitFront;
    dataKit.push(data);
  }

  return dataKit;
};
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IScanReportingFront } from "../../backend/queries/types/frontDocument";
import { getScannedWithDates } from "../../backend/queries/userScanned";
import FormComponent from "../../components/forms/formcomponent";
import { useAlert } from "../../provider/error/hooks/useAlert";
import { Timestamp } from "firebase/firestore";
import { CSVLink } from "react-csv";

const DashboardHome = () => {
  const {watch, formState, control, setValue} = useForm();
  const [lastScanned, setLastScanned] = useState<Array<IScanReportingFront>>();
  const [loading, setLoading] = useState<boolean>();
  const alert = useAlert();
  const {t} = useTranslation();

  useEffect(() => {
    setValue("startDateResearch", new Date(new Date().setHours(0, 0, 0, 0)));
    setValue("endDateResearch", new Date(new Date().setHours(23, 59, 59, 999)));
  }, []);

  useEffect(() => {
    (async() => {
      if (watch("startDateResearch") && watch("endDateResearch")) {
        setLoading(true);
        const startDate = watch("startDateResearch") as Date;
        const endDate = watch("endDateResearch") as Date;
        try {
          const lastSnap = await getScannedWithDates(
            Timestamp.fromDate(startDate),
            Timestamp.fromDate(endDate)
          );
          setLastScanned(lastSnap);
        } catch (error) {
          alert?.createAlert({
            type: "error",
            message: t("errorStandard")
          });
        }
        setLoading(false);
      }
    })();
  }, [watch("startDateResearch"), watch("endDateResearch")]);

  return(
    <>
      <FormComponent 
        watch={watch}
        formState={formState}
        control={control}
        arrayForms={[
          {
            type: "DatePicker",
            elementDatePicker: {
              name: "startDateResearch",
              label: "startDateResearch",
              maxDate: new Date(),
            }
          },
          {
            type: "DatePicker",
            elementDatePicker: {
              name: "endDateResearch",
              label: "endDateResearch",
              maxDate: new Date(new Date().setHours(0, 0, 0, 0)),
            }
          }
        ]}
      />

      {lastScanned && !loading && <CSVLink
        data={lastScanned}
        target="_blank"
        separator=";"
        filename="ReportingScans.csv"
      >
        {t("download")}
      </CSVLink>}
    </>
  );
};

export default DashboardHome;

import React, { useState } from "react";

// Components
import {
  Button, 
  Box,
  Typography
} from "@mui/material";

// Forms
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FormComponent from "../../components/forms/formcomponent";
import { signinForm } from "../../components/forms/asset/auth/signin";

// Hooks
import { useAuth } from "../../provider/auth/hooks/useAuth";
import { ForgotPassword } from "../../components/utils/auth/forgotPassword";

const Signin = () => {
  const {control, watch, formState, handleSubmit} = useForm();
  const {t} = useTranslation();
  const auth = useAuth();

  const [open, setOpen] = useState<boolean>(false);

  const onSubmit = (data: {email: string, password: string}) => {
    if (auth) {
      auth.signin(data.email, data.password);
    }
  };

  return(
    <>
      <Box sx={{
        minHeight: "80vh",
        display: "flex",
        width: "100%",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
      }}>
        <Box pb={2}>
          <Typography variant="h3" component="h3">
            {t("auth.signin")}
          </Typography>
        </Box>
        <Box sx={{
          width: "100%",
          maxWidth: "600px"
        }}>
          <FormComponent 
            control={control}
            watch={watch}
            formState={formState}
            arrayForms={signinForm}
          />
          <Button
            onClick={handleSubmit(onSubmit as never)}
            id="btn-login"
            sx={{my: 2, width: "100%"}}
            variant="contained"
          >
            {t("auth.signin")}
          </Button>

          <Button onClick={() => setOpen(true)}>
            {t("forgotPass")}
          </Button>

          <ForgotPassword open={open} setOpen={setOpen} />
        </Box>
      </Box>
    </>
  );
};

export default Signin;

import { TArrayMenu } from "../../../views/layout/type/types";

import { routing } from "../../config";

export const arrayMenuAdminAdmin: TArrayMenu = [
  {path: routing.dashboard.index, title: "dashboard"},
  {path: routing.createUser.index, title: "createUserTitle"},
  {path: routing.addProduct.index, title: "addProduct"},
  {path: routing.createKit.index, title: "createKit"},
  {path: routing.seeKit, title: "seeKitTitle"},
  {path: routing.uploadStock.index, title: "uploadStockTitle"},
  {path: routing.transferStock.index, title: "transferStock"},
  {path: routing.deceasedPage.create, title: "createDeceasedPage"},
  {path: routing.deceasedPage.linkAdmin, title: "linkAnAdmin"},
  {path: routing.deceasedPage.handle, title: "handleDeceased"}
];

export const arrayMenuAdminUser: TArrayMenu = [
  {path: routing.transferStock.index, title: "transferStock"},
  {path: routing.deceasedPage.create, title: "createDeceasedPage"},
  {path: routing.deceasedPage.linkAdmin, title: "linkAnAdmin"},
  {path: routing.deceasedPage.handle, title: "handleDeceased"}
];
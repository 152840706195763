import {
  collection,
  addDoc,
  getDocs,
  updateDoc, 
  arrayUnion,
  getDoc,
} from "@firebase/firestore";
import {
  DocumentReference,
  query,
  Timestamp,
  where,
  limit,
  doc
} from "firebase/firestore";
import { collections, IDeath } from "type-absenso";
import { db } from "../config";
import { IFrontDeath } from "./types/frontDocument";

export const addDeathProfile = async ({
  errorAction,
  data,
}: {
    errorAction: () => void;
    data: IDeath<DocumentReference, Timestamp>
}) => {
  try {
    const deathRef = collection(db, collections.death);
    await addDoc(deathRef, data);
  } catch (error) {
    console.log(error);
    errorAction();
  }
};

export const searchProfileDeath = async ({
  errorAction,
  search
}: {
  errorAction: () => void;
  search: string;
}) => {
  try {
    const dataDeath: Array<IFrontDeath> = [];
    const deathRef = collection(db, collections.death);
    const q = query(
      deathRef,
      where("fullname", ">=", search.toUpperCase()),
      where("fullname", "<=", search.toLowerCase()+"\uf8ff"),
      limit(10),
    );
    const snaps = await getDocs(q);

    for await (const snap of snaps.docs) {
      const data = snap.data() as IDeath<
        DocumentReference, Timestamp
      > | undefined;

      if (data) {
        dataDeath.push({
          ...data,
          id: snap.id,
        });
      }
    }

    return dataDeath;
  } catch (error) {
    errorAction();
  }
};

export const getAllDeathInMyResponsability = async ({
  errorAction,
}: {
  errorAction: () => void;
}) => {
  try {
    const dataDeath: Array<IFrontDeath> = [];
    const deathRef = collection(db, collections.death);
    const q = query(
      deathRef,
      where("admin", "array-contains", "absenso"),
    );
    const snaps = await getDocs(q);

    for await (const snap of snaps.docs) {
      const data = snap.data() as IDeath<DocumentReference, Timestamp>;
      dataDeath.push({
        ...data,
        id: snap.id,
      });
    }

    return dataDeath;
  } catch (error) {
    errorAction();
  }
};

export const getOneDeath = async({
  errorAction,
  id
}: {
  id: string;
  errorAction: () => void;
}) => {
  try {
    const deathDoc = doc(db, collections.death, id);
    const snap = await getDoc(deathDoc);
    const data = snap.data() as IDeath<
      DocumentReference, Timestamp
    > | undefined;

    if (data) {
      const dataWithId: IFrontDeath = {
        ...data,
        id: snap.id
      };

      return dataWithId;
    }
  } catch (error) {
    errorAction();
  }
};

export const addAdminForADeath = async ({
  idDeath,
  idUser,
}: {
  idDeath: string;
  idUser: string;
}) => {
  const deathRef = doc(db, collections.death, idDeath);
  const userRef = doc(db, collections.users, idUser);
  await updateDoc(deathRef, {
    admin: arrayUnion(userRef)
  });
};

export const updateDeath = async ({
  id,
  data
}: {
  id: string;
  data: {[x: string]: never};
}) => {
  const deathRef = doc(db, collections.death, id);
  await updateDoc(deathRef, data);
};
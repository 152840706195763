import {
  collection,
  DocumentReference,
  getDocs,
  query, 
  Timestamp,
  where
} from "firebase/firestore";
import { collections, IScanReporting } from "type-absenso";
import { db } from "../config";
import { IScanReportingFront } from "./types/frontDocument";

export const getScannedWithDates = async(
  startDate: Timestamp,
  endDate: Timestamp,
) => {
  const scannedArray: Array<IScanReportingFront> = [];
  const userScannedRef = collection(db, collections.userScanned);
  const q = query(
    userScannedRef,
    where("date", ">=", startDate),
    where("date", "<=", endDate)
  );

  const snaps = await getDocs(q);

  for await (const snap of snaps.docs) {
    const data = snap.data() as IScanReporting<DocumentReference, Timestamp>;
    scannedArray.push({
      ...data,
      id: snap.id,
      date: data.date.toDate().toLocaleString("fr-EU"),
      death: data.death.id,
      thingScanned: data.thingScanned.id,
      userScanned: data.userScanned === "unknown" ?
        data.userScanned : data.userScanned.id
    });
  }

  return scannedArray;
};